import React from 'react';
import {
  Grid,
  Dialog,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  MenuProps,
} from '@material-ui/core';
import { Tooltip } from 'portal-commons';
import parse from 'html-react-parser';
import { ModalType, VettingClassType, VettingProvider } from '../../types';
import { SelectItem, EmptyString } from '../../../../../utils/types';

interface Props {
  vettingProviders: Array<VettingProvider>;
  vettingClasses: Array<SelectItem>;
  handleClose: () => void;
  handleChange: (_key: string, _value: string) => void;
  handleError: (_key: string, _value: string) => void;
  submitVettingRequest: () => void;
  newVettingData: { title: string; text: string } | EmptyString;
  importVettingData: { title: string; text: string } | EmptyString;
  extVettingProviderId: string;
  error: Record<string, string>;
  vettingToken: string;
  vettingId: string;
  modalType: ModalType;
  vettingClass: VettingClassType | EmptyString;
  checked: boolean;
  vettingLoader: boolean;
}

const BrandVettingModal: React.FC<Props> = ({
  vettingProviders,
  vettingClasses,
  handleClose,
  handleChange,
  handleError,
  submitVettingRequest,
  newVettingData,
  importVettingData,
  extVettingProviderId,
  error,
  vettingToken,
  vettingId,
  modalType,
  vettingClass,
  checked,
  vettingLoader,
}) => {
  const selectMenuProps: Partial<MenuProps> = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  const vettingProvider: VettingProvider | undefined = extVettingProviderId
    ? vettingProviders.find((item) => item.uid === extVettingProviderId)
    : undefined;

  const displayModalTitle = () => {
    if (modalType === ModalType.newVetting) {
      return newVettingData && newVettingData.title
        ? newVettingData.title
        : 'Apply for New Vetting';
    }
    return importVettingData && importVettingData.title
      ? importVettingData.title
      : 'Import Vetting';
  };

  const displayVettingPrice = () => {
    if (vettingClass === 'ENHANCED' && extVettingProviderId === 'AEGIS') {
      return `$95`;
    } else if (
      vettingClass === 'POLITICAL' &&
      extVettingProviderId === 'AEGIS'
    ) {
      return '$64.00 - $91.95 (Depending on the delivery method)';
    } else if (
      vettingClass &&
      vettingProvider &&
      vettingProvider.vettingInstruction?.newVet?.price?.[vettingClass]
    ) {
      return `$${vettingProvider.vettingInstruction.newVet.price[vettingClass]}`;
    } else {
      return '--';
    }
  };

  const displayVettingTime = () => {
    if (vettingClass === 'ENHANCED' && extVettingProviderId === 'AEGIS') {
      return `2 - 3 months`;
    } else if (
      vettingClass === 'POLITICAL' &&
      extVettingProviderId === 'AEGIS'
    ) {
      return 'Under normal circumstances, you can expect your PIN code to be sent within one business day of application. Final vetting will occur within an hour of your PIN confirmation action.';
    } else if (
      vettingProvider &&
      vettingProvider.vettingInstruction.newVet &&
      vettingProvider.vettingInstruction.newVet.waitTime
    ) {
      return vettingProvider.vettingInstruction.newVet.waitTime;
    } else {
      return '--';
    }
  };

  const disableSubmit = () => {
    const checkVettingToken =
      extVettingProviderId === 'AEGIS' ? vettingToken : true;
    const checkExtVettingProviderId =
      modalType === ModalType.importVetting
        ? extVettingProviderId && vettingId && checkVettingToken
        : extVettingProviderId;
    let isDisabled = true;
    if (checkExtVettingProviderId) {
      isDisabled = checked ? vettingLoader : true;
    }
    return isDisabled;
  };

  return (
    <Dialog
      open={true}
      onClose={(_e, reason) => {
        !(reason === 'backdropClick') && handleClose();
      }}
      aria-labelledby="responsive-dialog-title"
      className="brand-vetting-modal"
      data-testid="brandVettingModal"
    >
      <div className="modal-wrapper">
        <div className="modal-content">
          <div className="modal-info">
            <h3 className="heading1">{displayModalTitle()}</h3>
            <div className="paragraph">
              {modalType === ModalType.newVetting
                ? newVettingData
                  ? parse(newVettingData.text)
                  : importVettingData
                  ? parse(importVettingData.text)
                  : null
                : null}
            </div>
          </div>
          <form onSubmit={submitVettingRequest}>
            {modalType === ModalType.newVetting ? (
              <div className="form-group-field">
                <FormControl
                  style={{ width: '100%' }}
                  data-testid="vettingTypeField"
                >
                  <InputLabel>Select Vetting Type</InputLabel>
                  <Select
                    MenuProps={selectMenuProps}
                    value={vettingClass}
                    fullWidth={true}
                    inputProps={{
                      'aria-label': 'vettingClass',
                      'data-testid': 'vettingTypeSelect',
                    }}
                    onChange={(event) =>
                      handleChange('vettingClass', event.target.value as string)
                    }
                  >
                    {vettingClasses &&
                      vettingClasses.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                {/* <Tooltip
                      title={'Vetting partners may provide independent information to the carrier about the Brand which may result in the Brand having access to a better commecial terms.'}
                    /> */}
              </div>
            ) : null}
            <div className="form-group-field">
              <FormControl
                style={{ width: '100%' }}
                data-testid="vettingPartnerField"
              >
                <InputLabel>Select Vetting Partner</InputLabel>
                <Select
                  MenuProps={selectMenuProps}
                  value={extVettingProviderId}
                  fullWidth={true}
                  disabled={
                    (vettingClass && vettingProviders.length) ||
                    modalType === ModalType.importVetting
                      ? false
                      : true
                  }
                  inputProps={{
                    'aria-label': 'evpUId',
                    'data-testid': 'vettingPartnerSelect',
                  }}
                  onChange={(event) =>
                    handleChange('evpUid', event.target.value as string)
                  }
                >
                  {vettingProviders.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.uid}>
                        {item.displayName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Tooltip
                title={
                  'Vetting partners may provide independent information to the carrier about the Brand which may result in the Brand having access to better commercial terms.'
                }
              />
              {vettingClass && vettingProviders.length === 0 ? (
                <h6 className="error-msg">
                  No Vetting Partners available for the Vetting Type
                </h6>
              ) : null}
            </div>
            {modalType === ModalType.importVetting ? (
              <div className="form-group-field">
                <TextField
                  error={error.vettingId ? true : false}
                  value={vettingId}
                  id="vettingId"
                  fullWidth={true}
                  minRows={1}
                  maxRows={3}
                  multiline={true}
                  label="Vetting Identifier"
                  inputProps={{
                    'aria-label': 'vettingId',
                    'data-testid': 'vettingIdInputField',
                  }}
                  onChange={(event) =>
                    handleChange('vettingId', event.target.value)
                  }
                  onBlur={(event) =>
                    handleError('vettingId', event.target.value)
                  }
                />
                {error.vettingId ? (
                  <h6 className="error-msg">{error.vettingId}</h6>
                ) : null}
              </div>
            ) : null}
            {modalType === ModalType.importVetting &&
            extVettingProviderId === 'AEGIS' ? (
              <div className="form-group-field">
                <TextField
                  error={error.vettingToken ? true : false}
                  value={vettingToken}
                  id="vettingId"
                  fullWidth={true}
                  minRows={1}
                  maxRows={3}
                  multiline={true}
                  label="Vetting Token"
                  inputProps={{
                    'aria-label': 'vettingToken',
                    'data-testid': 'vettingTokenInputField',
                  }}
                  onChange={(event) =>
                    handleChange('vettingToken', event.target.value)
                  }
                  onBlur={(event) =>
                    handleError('vettingToken', event.target.value)
                  }
                />
                {error.vettingToken ? (
                  <h6 className="error-msg">{error.vettingToken}</h6>
                ) : null}
              </div>
            ) : null}

            {vettingProvider && modalType === ModalType.newVetting ? (
              vettingProvider.vettingInstruction.newVet ? (
                <Grid item xs={12}>
                  <div
                    className="vetting-details"
                    data-testid="vettingDetailBlock"
                  >
                    <h3 className="paragraph text-center">
                      <span>Vetting Price: </span>
                      {displayVettingPrice()}
                      <br />
                      <span>Estimated Time for Vetting: </span>
                      {displayVettingTime()}
                    </h3>
                  </div>
                </Grid>
              ) : null
            ) : null}
            {/* {(modalType === ModalType.importVetting && vettingLoader) ? <Grid container className="vetting-applied-wrapper">
                    <Grid item xs={12}>
                        <img src={`${S3_ASSETS_PATH}/images/${importVettingError ? 'vetting-error': 'vettingPending'}.svg`} alt=""/>
                        {importVettingError ? <h3 className="paragraph confirm">We were unable to confirm <br/>your vetting information</h3> : <h3 className="paragraph confirm">We are confirming your data with <br/>the vetting partner. <span>Please wait.</span></h3>}
                    </Grid>
                    {importVettingError ? <Grid item xs={12} style={{textAlign: 'center'}}>
                        <div className="form-group-field" style={{marginBottom: '0'}}>
                            <input type="submit" className="primary-btn" value={'Close'} onClick={() => handleClose('modal3')} style={{width: 'auto', marginTop: '30px'}}/>
                        </div>
                    </Grid> : null}
                </Grid> : <><Grid item xs={12}>
                <label>
                    <div className="check-box-span" style={{textAlign: 'center'}}>
                        <CheckBox
                            checked={checked}
                            handleCheckbox={handleCheckBox}
                        />
                        <span>I agree to the <a href="#">Terms & Conditions </a></span>
                    </div>
                </label>
            </Grid> */}
            {/* <Grid item xs={12}>
                <label>
                    <div className="check-box-span" style={{textAlign: 'center'}}>
                        <CheckBox
                            checked={checked}
                            handleCheckbox={handleCheckBox}
                        />
                        <span>I agree to the <a href="#">Terms & Conditions </a></span>
                    </div>
                </label>
            </Grid> */}
            <Grid item className="modal-footer">
              <ul className="list-inline text-center">
                <li>
                  <a
                    href="#"
                    onClick={() => handleClose()}
                    className="secondary-btn"
                    data-testid="brandVettingModalCancelButton"
                  >
                    Cancel
                  </a>
                </li>
                <li>
                  <div className="form-group-field">
                    <input
                      type="submit"
                      disabled={disableSubmit()}
                      className="primary-btn"
                      data-testid="brandVettingModalSubmitButton"
                      value={
                        vettingClass === 'POLITICAL' &&
                        extVettingProviderId === 'AEGIS'
                          ? 'Next'
                          : 'Submit'
                      }
                    />
                  </div>
                </li>
              </ul>
            </Grid>
          </form>
        </div>
      </div>
    </Dialog>
  );
};
export default BrandVettingModal;
