import React from 'react';
import ExpansionList from '../../../../ui_elements/ExpansionList';
import { ALPHABETS } from '../../../../constants';
import { checkDisplayStatus } from '../utils';

export default function DocSummaryNew(props) {
  const { activeContent = {} } = props;
  return (
    <div className="doc-summary-blk" data-testid="docSummary">
      <div className="title">
        <h3 className="heading1">
          {activeContent.title && activeContent.title.toUpperCase()}
        </h3>
      </div>
      <div className="content-blk">
        <ul className="list-unstyled">
          {activeContent.sections &&
            activeContent.sections
              .filter((item) => checkDisplayStatus(item.status))
              .map((item, index) => (
                <li key={index}>
                  {/* <span>{index + 1 < 10 ? `0${index + 1}` : index + 1}.</span> */}
                  {item.subsections && item.subsections.length > 0 ? (
                    <ExpansionList docflag="true" title={item.title}>
                      <ul className="subsection-list">
                        {item.subsections
                          .filter((item) => checkDisplayStatus(item.status))
                          .map((item, subIndex) => (
                            <li key={subIndex}>
                              {/* <span>{ALPHABETS[subIndex]}.</span> */}
                              <a href={`#${index + 1}${ALPHABETS[subIndex]}`}>
                                {item.title}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </ExpansionList>
                  ) : (
                    <a href={`#${index + 1}`}>{item.title}</a>
                  )}
                </li>
              ))}
        </ul>
      </div>
    </div>
  );
}
